@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  font-family: Avenir, sans-serif;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  padding: 15px 15px;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-out;

  .contentWrapper {
    display: flex;
    position: relative;
    background: #ffffff;
    box-shadow: 0 3px 14px rgba(74, 58, 255, 0.03), 0px -2px 4px rgba(20, 20, 43, 0.12),
      0px 12px 44px rgba(20, 20, 43, 0.34);
    border-radius: 5px;
    min-height: 600px;
    width: 900px;
    max-width: 100%;
    margin: auto;
    transform: translateY(-160px);
    transition: all 0.3s ease-out;
    overflow: hidden;
  }

  &.isVisible {
    opacity: 1;
    visibility: visible;

    .contentWrapper {
      transform: translateY(0);

      .loadingWrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        font-size: 32px;
        background-color: #FFFFFF;

        &.enable {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
